import React from 'react';
import './BoardColumn.css';

const BoardColumn = ({ title, cards }) => {
    return (
        <div className="column">
            <header>{title}</header>
            {cards.map(card => (
                <div key={card.id} className="card">
                    {card.text}
                </div>
            ))}
        </div>
    );
};

export default BoardColumn;
