import React from 'react';
import BoardColumn from './BoardColumn';
import SearchBar from './SearchBar';
import './Board.css';

const Board = ({ columns }) => {
    return (
        <div className="boardContainer">
            <SearchBar />
            <div className="columnsContainer">
                {columns.map(column => (
                    <BoardColumn key={column.id} title={column.title} cards={column.cards} />
                ))}
            </div>
        </div>
    );
};

export default Board;
