import React, { useState, useEffect } from 'react';
import "./App.css";
import Board from "./components/Board";
import SidebarContext from './contexts/SidebarContext';
import Header from './components/Header';
import Sidebar from "./components/Sidebar";
import LoginButton from "./components/prev_login";
import LogoutButton from "./components/logout";
import { gapi } from 'gapi-script';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {

    useEffect(() => {
        const start = () => {
            gapi.client.init({
                clientId: clientId,
                scope: "https://www.googleapis.com/auth/gmail.readonly"
            });
        };
    
        if (window.gapi) {
            gapi.load('client:auth2', start);
        }
        // gapi.load('client:auth2', start);

    }, [clientId]);  // Only re-run this effect if clientId changes

    useEffect(() => {
        const fetchData = async () => {
            // Simulated API response
            const data = [
                { id: 1, title: 'Appointment Scheduled', cards: [{ id: 101, text: 'Meeting with client' }] },
                { id: 6, title: 'Qualified to Buy', cards: [{ id: 201, text: 'Follow up on proposal' }] },
                { id: 3, title: 'Other stuff to Buy', cards: [{ id: 201, text: 'Do stuff' }, { id: 101, text: 'Meeting with client' }] },
                // Additional data can be added here
            ];
            setColumns(data);
        };
    
        fetchData();
    }, []);  // This effect does not depend on any values from props or state, so it only runs once

    const [isSidebarOpen, setSidebarOpen] = useState(true);

    const [columns, setColumns] = useState([]);


    return (
        <SidebarContext.Provider value={{ isSidebarOpen, setSidebarOpen }}>
            <div className="App">
                <Header />
                <div className="app-body">
                    {isSidebarOpen && <Sidebar />}
                    
                    {/* 
                    <LoginButton />
                    <LogoutButton /> */}

                    <div className="main-content">
                        <Board columns={columns} />
                    </div>
                </div>
            </div>
        </SidebarContext.Provider>

    );
}
 

export default App;