import React, { useState } from 'react';
import './SearchBar.css';

// TODO: Remove this import
import { gapi } from 'gapi-script';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (e) => {
        console.log("setSearchTerm(e.target.value)")
        console.log(e.target.value)
        console.log(e)
        setSearchTerm(e.target.value);
    };

    const onButtonClick = () => {
        // TODO: handle button click logic here

        // TODO: Delete this auth logic
        if (gapi && gapi.auth && gapi.auth.getToken()) {
            const auth2 = gapi.auth2.getAuthInstance();
            auth2.grantOfflineAccess().then((resp) => {
                console.log("Authorization response: ", resp);
                // Send the authorization code to the backend
                fetch('PHP/store_token.php', { // https://app.thorexa.com/PHP/store_token.php
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ auth_code: resp.code })  // send only the authorization code
                })
                .then(data => console.log('Data received:', data))
                .catch((error) => console.error('Error:', error));
            });
        }
    };

    return (
        <div className="searchBar">
            <input
                type="search"
                placeholder="Search name or description"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <button onClick={onButtonClick}>Search</button>
        </div>
    );
};

export default SearchBar;
