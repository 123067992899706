// TODO: Update the GoogleLogin to -> https://blog.logrocket.com/guide-adding-google-login-react-app/
import { GoogleLogin } from 'react-google-login';
import { useContext } from 'react';
import SidebarContext from '../contexts/SidebarContext'; // Importing context if needed for some additional logic
import { gapi } from 'gapi-script';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;


function Login() {
    const { setSidebarOpen } = useContext(SidebarContext); // Using context if needed to manage sidebar

    const onSuccess = (res) => {
        console.log("LOGIN SUCCESS! Current user: ", res.profileObj);
        // You can close the sidebar on login success if that makes sense in your UX

        // Additional OAuth2 information to be sent to the backend
        var userInfo = {
            email_id: res.profileObj.googleId,
            user_name: res.profileObj.name,
            email_address: res.profileObj.email,
            email_token: res.profileObj.email_token
        };
        
        console.log("Checking...");
        if (gapi && gapi.auth && gapi.auth.getToken()) {
            console.log("Ok where in..");

            const auth2 = gapi.auth2.getAuthInstance();
            auth2.grantOfflineAccess().then((resp) => {
            // var resp = res;
                console.log("Authorization response: ", resp);
                // Send the authorization code to the backend
                fetch('PHP/store_token.php', { // https://app.thorexa.com/PHP/store_token.php
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ auth_code: resp.code,
                                            ...userInfo
                     })  // send only the authorization code
                })
                .then(data => console.log('Data received:', data))
                .catch((error) => console.error('Error:', error));
            });
        }
    }

    const onFailure = (res) => {
        console.log("LOGIN FAILED res: ", res);
    }

    return (
        <div id="signInButton" className="sidebar-login">
            <GoogleLogin
                clientId={clientId}
                buttonText="Login"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
                style={{ width: '100%', justifyContent: 'center' }} // Style adjustments for sidebar
            />
        </div>
    )
}

export default Login;
