// components/Header.js
import React, { useState, useContext } from 'react';
import './Header.css';
import SidebarContext from '../contexts/SidebarContext';

function Header() {
  const { isSidebarOpen, setSidebarOpen } = useContext(SidebarContext);

  const toggleSidebar = (event) => {
    event.stopPropagation();
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    // <header id="header" className="header fixed-top d-flex align-items-center">
    //   <div className="d-flex align-items-center justify-content-between">
    //     <a href="index.html" className="logo d-flex align-items-center">
    //       <img src="assets/img/logo.png" alt="" />
    //       <span className="d-none d-lg-block">NiceAdmin</span>
    //     </a>
    //     <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
    //   </div>

    //   <div className="search-bar">
    //     <form className="search-form d-flex align-items-center" method="POST" action="#">
    //       <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
    //       <button type="submit" title="Search"><i className="bi bi-search"></i></button>
    //     </form>
    //   </div>

    //   <nav className="header-nav ms-auto">
    //     {/* Insert the rest of the nav elements here */}
    //   </nav>
    // </header>

    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
         <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
         <a href="index.html" className="logo d-flex align-items-center">
           <img src="small_logo.png" alt="" />
           <span className="d-none d-lg-block">Thorexa</span>
         </a>
        </div>
      {/* </div>
         <div className="search-bar">
         <form className="search-form d-flex align-items-center" method="POST" action="#">
           <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
           <button type="submit" title="Search"><i className="bi bi-search"></i></button>
         </form>
       </div> */}

       <nav className="header-nav ms-auto">
         {/* Insert the rest of the nav elements here */}
       </nav>
    </header>
  );
}

export default Header;
